<template>
  <a-modal
    title="轮巡结果"
    :visible="visible"
    width="94%"
    :bodyStyle="bodyStyle"
    :centered="true"
    :footer="null"
    :destroyOnClose="true"
    @cancel="handleCancel"
  >
    <div class="content full-height">
      <div class="flex justify-between items-center m-b-sm">
        <div class="flex-one items-center text-lightblue">
          <span class="flex-s m-r-xs">巡检日期：</span>
          <a-range-picker
            v-model="turnDateRange"
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm"
            :placeholder="['开始时间', '结束时间']"
            class="m-r-sm search-date-picker"
            @change="onChange"
          />
          <a-button
            type="primary"
            class="m-r-sm btn-search"
            @click="searchHandle"
          >
            搜索
          </a-button>
          <a-button class="btn-reset m-r-sm" @click="resetHandle" v-if="false">
            重置
          </a-button>
        </div>
      </div>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => index"
        :components="resibleTableHeader"
        :data-source="data"
        :pagination="paginationOpt"
        bordered
        :scroll="scroll"
        :loading="loading"
      >
        <template slot="workOrderNameSlot" slot-scope="text, record">
          <span v-if="currentData.cameraPatrolName"
            >{{ currentData.cameraPatrolName }}-</span
          >
          <span v-if="record.workOrderName">{{ record.workOrderName }}</span>
        </template>
        <template slot="action" slot-scope="text, record">
          <div class="examine" @click="viewResult(record)">查看</div>
        </template>
      </a-table>
    </div>
    <!-- 查看结果弹窗 -->
    <turn-result-dialog
      :id="currentId"
      :workOrderData="workOrderData"
      :visible="turnResultVisible"
      @close="turnResultVisible = false"
    />
  </a-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CODE_OK } from '@/request/config_code';
import TurnResultDialog from './TurnResultDialog.vue';
import moment from 'moment';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'TurnWorkOrderDialog',
  mixins: [ResizableTable],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      default: null
    },
    currentData: {
      type: Object,
      default: () => {
        return {
          duration: null,
          totalQuantity: null,
          onlineQuantity: null,
          errorQuantity: null,
          breakdownQuantity: null
        };
      }
    }
  },
  components: {
    TurnResultDialog
  },
  data() {
    return {
      bodyStyle: { height: `${window.innerHeight - 80}px`, x: '100%' },
      turnResultVisible: false,
      currentId: -1,
      workOrderData: null,
      turnDateRange: null,
      startTime: '',
      endTime: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'resultIndex',
          customRender: (text, record, index) => index + 1,
          width: 80,
          align: 'center',
          ellipsis: true
        },
        {
          title: '工单名称',
          dataIndex: 'workOrderName',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'workOrderNameSlot' }
        },
        {
          title: '巡检日期',
          dataIndex: 'createTime',
          width: 200,
          align: 'center',
          ellipsis: true
        },
        {
          title: '巡检视频数',
          dataIndex: 'totalQuantity',
          width: 200,
          align: 'center',
          ellipsis: true
        },
        {
          title: '巡检结果',
          children: [
            {
              title: '在线',
              dataIndex: 'onlineQuantity',
              key: 'onlineQuantity',
              width: 80,
              align: 'center',
              ellipsis: true
            },
            {
              title: '离线',
              dataIndex: 'offlineQuantity',
              key: 'offlineQuantity',
              width: 80,
              align: 'center',
              ellipsis: true
            },
            {
              title: '故障',
              dataIndex: 'breakdownQuantity',
              key: 'breakdownQuantity',
              width: 80,
              align: 'center',
              ellipsis: true
            },
            {
              title: '异常',
              dataIndex: 'errorQuantity',
              key: 'errorQuantity',
              width: 80,
              align: 'center',
              ellipsis: true
            }
          ]
        },
        {
          title: '巡检详情',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          className: 'column-center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: total => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getWorkOrderInfo();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this._getWorkOrderInfo();
        }
      },
      scroll: { y: window.innerHeight - 400 },
      loading: false
    };
  },
  computed: {
    ...mapState({
      organizationList: state => state.collect.organizationList
    })
  },
  watch: {
    visible(nv, ov) {
      if (nv && nv !== ov) {
        this.paginationOpt.current = 1;
        this.paginationOpt.defaultPageSize = 10;
        this._getWorkOrderInfo();
      }
    }
  },
  created() {
    this.getOrganationList();
  },
  methods: {
    ...mapActions(['getOrganationList']),

    getStyle() {
      return { height: `${document.documentElement.clientHeight - 200}px` };
    },
    viewResult(record) {
      console.log('record', record);
      this.currentId = record.workOrderId;
      this.workOrderData = record;
      this.turnResultVisible = true;
    },
    onChange(value) {
      /* console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString); */
      this.turnDateRange = value;
      this.startTime =
        this.turnDateRange.length > 0
          ? moment(this.turnDateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : '';
      this.endTime =
        this.turnDateRange.length > 0
          ? moment(this.turnDateRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : '';
      // console.error('-----------------', value, this.cameraPatrolEndTime);
    },

    _getWorkOrderInfo() {
      let params = {
        planId: this.id,
        startTime: this.startTime || null,
        endTime: this.endTime || null,
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize
      };
      this.loading = true;
      this.$api.cameraPatrol.getWorkOrderInfo(params).then(res => {
        if (res.code === CODE_OK) {
          this.loading = false;
          this.data = res.data;
          this.paginationOpt.total = res.total;
        }
      });
    },
    searchHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getWorkOrderInfo();
    },
    resetHandle() {
      this.startTime = '';
      this.endTime = '';
      this.turnDateRange = null;
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this.data = [];
      this.searchHandle();
    },
    handleCancel() {
      this.resetHandle();
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.examine {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 88px;
  height: 100%;
  height: 26px;
  font-size: 12px;
  cursor: pointer;
  background: #182842;
}
</style>
