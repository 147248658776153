<template>
  <div class="full-width">
    <div class="flex items-center justify-between full-width">
      <div class="flex items-center flex-one text-lightblue">
        <span class="flex-s m-r-xs">状态：</span>
        <a-select
          v-model="curStatus"
          class="m-r-sm search-mx-wd-1"
          @change="handleStatusChange"
        >
          <a-select-option
            v-for="item in status"
            :value="item.value"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <span class="flex-s m-r-xs">用户：</span>
        <a-select
          v-model="curAccount"
          class="m-r-sm search-mx-wd-1"
          @change="handleAccountChange"
        >
          <a-select-option
            v-for="item in account"
            :value="item.value"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <span class="flex-s m-r-xs">预案名称：</span>
        <a-input
          v-model="tempName"
          placeholder="请输入预案名称"
          class="m-r-sm search-mx-wd-1"
        />
        <span class="flex-s m-r-xs" v-if="false">日期：</span>
        <a-range-picker
          v-if="false"
          v-model="turnDateRange"
          :show-time="{ format: 'HH:mm' }"
          format="YYYY-MM-DD HH:mm"
          :placeholder="['开始时间', '结束时间']"
          class="m-r-sm search-date-picker"
          @change="onChange"
        />
        <a-button
          type="primary"
          class="m-r-sm btn-search"
          @click="searchHandle"
        >
          搜索
        </a-button>
        <a-button class="btn-reset" @click="resetHandle">
          重置
        </a-button>
      </div>
      <div class="flex">
        <span class="span-line m-l-md m-r-md"></span>
        <a-button @click="newTurnHandle" class="btn-export"
          ><a-icon type="plus-circle" class="m-r-xs" />新建轮巡</a-button
        >
        <!--  <a-button  @click="exportHandle" class="btn-export">导出</a-button> -->
      </div>
    </div>
    <turn-dialog
      @closeDialog="turnVisible = false"
      @refreshData="refreshDataHandle"
      selectAllMode="all"
      :visible="turnVisible"
    ></turn-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import TurnDialog from '@/components/TurnDialogPolling';
import { mapState, mapActions, mapMutations } from 'vuex';
import { setStorage } from '@/tools';
export default {
  name: 'SearchBar',
  data() {
    return {
      status: [
        {
          id: 100,
          value: '',
          name: '全部'
        },
        {
          id: 0,
          value: 0,
          name: '待执行'
        },
        {
          id: 1,
          value: 1,
          name: '执行中'
        },
        {
          id: 2,
          value: 2,
          name: '执行结束'
        },
        {
          id: 3,
          value: 3,
          name: '暂停执行'
        }
      ],
      account: [],
      curStatus: '',
      curAccount: '',
      tempName: '',
      turnVisible: false,
      cameraPatrolStartTime: '',
      cameraPatrolEndTime: '',
      turnDateRange: null
    };
  },
  computed: {
    ...mapState({
      userNames: state => state.cameraPatrol.userNames
    })
  },
  watch: {},
  components: {
    TurnDialog
  },
  mounted() {
    this.getUserName().then(res => {
      if (res.code === 200) {
        setStorage('userAccount', this.userNames);
        this.userNames.unshift('全部');
        this.account = this.userNames.map((item, index) => {
          return {
            id: index,
            value: index === 0 ? '' : item,
            name: item
          };
        });
      }
    });
  },
  methods: {
    ...mapMutations(['setSearchParams', 'setSearchParams']),
    ...mapActions(['getUserName', 'getCameraPatrolPlanList']),
    exportHandle() {
      this.$emit('export');
    },
    handleStatusChange(val) {
      this.curStatus = val;
    },
    handleAccountChange(val) {
      this.curAccount = val;
    },
    onChange(value) {
      /* console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString); */
      this.turnDateRange = value;
      this.cameraPatrolStartTime =
        this.turnDateRange.length > 0
          ? moment(this.turnDateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : '';
      this.cameraPatrolEndTime =
        this.turnDateRange.length > 0
          ? moment(this.turnDateRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : '';
      // console.error('-----------------', value, this.cameraPatrolEndTime);
    },
    onOk(value) {
      console.log('onOk: ', moment(value[0]).unix());
    },
    newTurnHandle() {
      if (!this.hasPermission(10921510)) {
        this.permissionWarning();
        return;
      }

      this.turnVisible = true;
    },
    searchHandle() {
      let obj = {
        planStatus: this.curStatus,
        userId: this.curAccount,
        username: this.curAccount,
        planName: this.tempName
      };
      this.setSearchParams(obj);
      this.$emit('search', obj);
    },
    resetHandle() {
      // console.error(111);
      this.curStatus = '';
      this.curAccount = '';
      this.cameraPatrolStartTime = '';
      this.cameraPatrolEndTime = '';
      this.turnDateRange = null;
      this.tempName = '';
      this.searchHandle();
    },
    refreshDataHandle() {
      this.searchHandle();
    }
  }
};
</script>

<style scoped></style>
