<template>
  <div class="turn-dialog">
    <a-modal
      title="轮巡设置"
      width="32%"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :centered="true"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template>
        <div class="flex items-center m-b-sm">
          <span class="wd-name">方案名称：</span>
          <a-input
            v-model="tempName"
            class="flex-one"
            placeholder="请输入方案名称"
          />
        </div>
        <div class="flex items-center m-b-sm">
          <span class="wd-name">轮巡周期：</span>
          <a-checkbox :checked="!turnPeriod" @change="onChange">
            永久有效
          </a-checkbox>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="wd-name"></span>
          <a-checkbox :checked="turnPeriod" @change="onChange">
            执行时间
          </a-checkbox>
          <a-range-picker
            v-model="formState.execDate"
            :default-value="formState.execDate"
            format="YYYY-MM-DD"
            :allowClear="false"
            style="width: 248px"
            :disabled-date="disabledDate"
            @change="onChangeExecDate"
          />
        </div>
        <div class="flex">
          <span class="wd-name">执行时间：</span>
          <div class="execution-main">
            <div class="execution-main-add" @click="handleExecution">
              添加时段
            </div>
            <div class="time-box">
              <div
                class="execution-main-time"
                v-for="(t, index) in planTimeList"
                :key="index"
              >
                <a-select
                  v-model="t.executeType"
                  style="width: 70px"
                  @change="onChangeExecuteType(t)"
                >
                  <a-select-option value="DAY">每天</a-select-option>
                  <a-select-option value="WEEK">每周</a-select-option>
                  <a-select-option value="MONTH">每月</a-select-option>
                </a-select>
                <a-select
                  style="width: 70px"
                  v-if="t.executeType === 'WEEK'"
                  v-model="t.executeDate"
                >
                  <a-select-option
                    :value="item.val"
                    :key="item.val"
                    v-for="item in weekDayListOfExecute"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
                <a-select
                  style="width: 70px"
                  v-if="t.executeType === 'MONTH'"
                  v-model="t.executeDate"
                >
                  <a-select-option
                    :value="item.val"
                    :key="item.val"
                    v-for="item in monthDayListOfExecute"
                    >{{ item.label }}日</a-select-option
                  >
                </a-select>
                <a-time-picker
                  v-model="t.executeTime"
                  format="HH:mm:ss"
                  :allowClear="false"
                  :showNow="false"
                ></a-time-picker>
                <div class="time-delete" @click="handleTimeDel(index)"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="wd-name">选择内容：</span>
          <span class="text-lightblue flex items-center flex-one"
            ><a-icon type="plus-circle" class="m-r-sm" @click="addHandle" />已选
            <span class="text-line m-l-xs m-r-xs">{{
              selectCameraList.length
            }}</span>
            路摄像机</span
          >
        </div>
        <div class="flex items-center">
          <span class="wd-name">轮巡描述：</span>
          <div class="flex-one">
            <a-textarea
              placeholder="请输入轮巡描述"
              v-model="turnRemark"
              :rows="4"
              :maxLength="200"
            />
          </div>
        </div>
        <span class="tips text-right text-red block">200个字</span>
      </template>
    </a-modal>
    <cameral-select
      :visible="cameraSelectVisible"
      :cameraTabs="selectAllMode"
      @closeCameralDialog="closeCameralDialogHandle"
    />
  </div>
</template>

<script>
import CameralSelect from "@/components/CameralSelect";
import moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
const weekLabel = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];

const listOfWeekDay = new Array(7).fill("").map((item, index) => ({
  label: weekLabel[index],
  val: index + 1 + "",
}));

const listOfMonthDay = new Array(31).fill("").map((item, index) => ({
  label: index + 1,
  val: index + 1 + "",
}));
export default {
  name: "TurnDialogPolling",
  props: {
    visible: {
      type: Boolean,
    },
    selectAllMode: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmLoading: false,
      tempName: null, // 方案名称
      turnRemark: null, // 轮巡描述
      turnPeriod: false, // 轮巡周期
      cameraSelectVisible: false, // 添加摄像机
      formState: {
        // 执行时间
        execDate: [
          moment(moment().format("YYYY-MM-DD").split(" ")[0], "YYYY-MM-DD"),
          moment(
            moment().add(1, "month").format("YYYY-MM-DD").split(" ")[0],
            "YYYY-MM-DD"
          ),
        ],
      },
      planTimeList: [],
      weekDayListOfExecute: [...listOfWeekDay],
      monthDayListOfExecute: [...listOfMonthDay],
    };
  },
  beforeCreate() {},
  components: { CameralSelect },
  computed: {
    ...mapState({
      selectCameraList: (state) => state.collect.selectCameraList,
      editTurnParams: (state) => state.cameraPatrol.editTurnParams,
    }),
  },
  watch: {
    visible() {
      this.initTurnData();
    },
  },
  mounted() {
    this.initTurnData();
  },
  methods: {
    ...mapMutations([
      "setSelectCameraList",
      "setCurrentComIndex",
      "setEditTurnParams",
    ]),
    ...mapActions(["createCameraPatrol", "updateCameraPatrol"]),
    initTurnData() {
      if (this.editTurnParams) {
        this.tempName = this.editTurnParams.cameraPatrolName;
        this.turnPeriod =
          this.editTurnParams.execType == 1
            ? false
            : this.editTurnParams.execType == 2
            ? true
            : false;
        if (this.editTurnParams.startTime && this.editTurnParams.endTime) {
          this.formState.execDate = [
            moment(this.editTurnParams.startTime.split(" ")[0], "YYYY-MM-DD"),
            moment(this.editTurnParams.endTime.split(" ")[0], "YYYY-MM-DD"),
          ];
        } else {
          this.formState.execDate = [
            moment(moment().format("YYYY-MM-DD").split(" ")[0], "YYYY-MM-DD"),
            moment(
              moment().add(1, "month").format("YYYY-MM-DD").split(" ")[0],
              "YYYY-MM-DD"
            ),
          ];
        }
        console.log("paramsOk-111", this.formState);

        if (this.editTurnParams.cyclesTimes) {
          this.planTimeList = [];
          this.editTurnParams.cyclesTimes.map((it) => {
            this.planTimeList.push({
              executeType: it.cycle,
              executeDate: it.day.toString(),
              executeTime: moment(it.time, "HH:mm:ss"),
            });
          });
        }
        this.turnRemark = this.editTurnParams.description;
      }
    },
    handleOk(e) {
      if (!this.tempName) {
        this.$message.error("请输入方案名称");
        return false;
      }
      if (!(this.planTimeList.length > 0)) {
        this.$message.error("请添加时段");
        return false;
      }
      // 时段时间处理
      let repetitionList = [];
      let cyclesTimes = [];
      let planType = false;
      this.planTimeList.map((it) => {
        let time = null;
        it.executeTime && (time = it.executeTime.format("HH:mm:ss"));
        it.repetitionSting = time;
        // 添加接口入参
        cyclesTimes.push({
          cycle: it.executeType, // 周期 = ['DAY', 'WEEK', 'MONTH']
          day: it.executeDate ? parseInt(it.executeDate) : null, // 天数
          time: time, // 周期时间
        });
        repetitionList.push(`${it.executeType}-${it.executeType}-${time}`);
      });
      planType = this.hasDuplicate(repetitionList);
      if (planType) {
        this.$message.error("执行时段有重复");
        return false;
      }
      if (this.selectCameraList.length <= 0) {
        this.$message.error("请选择摄像机");
        return;
      }
      let cameraIds = [];
      this.selectCameraList.map((c) => {
        cameraIds.push(c.cameraId);
      });
      console.log("cameraIds", cameraIds);
      this.confirmLoading = true;
      // 参数
      const params = {
        cameraIds: cameraIds, // 摄像机ID
        cameraPatrolName: this.tempName,
        cyclesTimes: cyclesTimes, // 执行时间段
        description: this.turnRemark, // 轮询描述
        startTime: this.formState.execDate[0].format("YYYY-MM-DD HH:mm:ss"), // 轮巡执行开始时间
        endTime: this.formState.execDate[1].format("YYYY-MM-DD HH:mm:ss"), // 巡执行结束时间
        execType: this.turnPeriod ? 2 : 1, // 执行类型 1 永久 2 周期
      };
      console.log("paramsOk", params, this.editTurnParams, this.isEdit);
      if (this.isEdit) {
        let newData = {
          planId: this.editTurnParams.planId,
          params: params,
        };
        this.updateTurn(newData);
      } else {
        this.addTurn(params);
      }
      e.preventDefault();
    },
    updateTurn(data) {
      this.updateCameraPatrol(data)
        .then((data) => {
          if (data.code === 200) {
            this.$message.success("修改轮巡预案成功");
            this.resetData();
            this.$emit("refreshData");
          }
        })
        .catch(() => {
          this.resetData();
        });
    },
    addTurn(data) {
      let instructionsDel = null;
      let objParams = {
        data,
        instructionsDel,
      };
      this.createCameraPatrol(objParams)
        .then((data) => {
          if (data.code === 200) {
            this.$message.success("新增轮巡预案成功");
            this.resetData();
            if (this.selectAllMode === "all") {
              this.$emit("refreshData");
            }
          }
        })
        .catch(() => {
          this.resetData();
        });
    },
    resetData() {
      this.tempName = null;
      this.turnRemark = null;
      this.turnPeriod = false;
      this.formState.execDate = [
        moment(moment().format("YYYY-MM-DD").split(" ")[0], "YYYY-MM-DD"),
        moment(
          moment().add(1, "month").format("YYYY-MM-DD").split(" ")[0],
          "YYYY-MM-DD"
        ),
      ];
      this.planTimeList = [];
      this.confirmLoading = false;
      this.setSelectCameraList([]);
      this.setEditTurnParams(null);
      this.$emit("closeDialog");
    },
    handleCancel() {
      this.resetData();
    },
    // 周期
    onChange() {
      this.turnPeriod = !this.turnPeriod;
    },
    // 日期选择
    onChangeExecDate(val) {
      console.log(
        "日期选择",
        val,
        this.formState.execDate[0].format("YYYY-MM-DD")
      );
    },
    // 选择天、周、月
    onChangeExecuteType(val) {
      console.log("选择天、周、月", val, val.executeType);
      val.executeDate = "1";
    },
    // 添加时段
    handleExecution() {
      if (this.planTimeList.length >= 5) {
        this.$message.error("执行时段已添加到最大值");
        return false;
      }
      this.planTimeList.push({
        executeType: "DAY",
        executeDate: "0",
        executeTime: moment(moment().format("HH:mm:ss"), "HH:mm:ss"),
      });
    },
    // 移除时段
    handleTimeDel(index) {
      console.log("移除时段", this.planTimeList, index);
      this.planTimeList.splice(index, 1);
    },
    getCameraExtraData() {
      return {
        cameraPatrolFlag: 2,
        cameraPatrolInfo: this.selectCameraList.map((item) => item.cameraId),
      };
    },
    addHandle() {
      this.cameraSelectVisible = true;
      this.setCurrentComIndex(0);
    },
    closeCameralDialogHandle() {
      this.cameraSelectVisible = false;
    },
    // 查找数组是否重复
    hasDuplicate(arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr.includes(arr[i], i + 1)) {
          return true; // 找到重复项
        }
      }
      return false; // 没有找到重复项
    },
    // 禁用不可选日期
    disabledDate(current) {
      return current && current < moment().subtract(1, "day").endOf("day");
    },
  },
};
</script>

<style scoped>
.wd-name {
  width: 80px;
}
.tips {
  padding-left: 80px;
}
.execution-main {
  width: calc(100% - 80px);
  margin-bottom: 10px;
}
.execution-main-add {
  width: 342px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 14px;
  color: rgba(134, 179, 233, 1);
  background: rgba(72, 97, 132, 1);
  cursor: pointer;
}
.time-box {
  max-height: 300px;
  overflow-y: auto;
}
.execution-main-time {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}
.time-delete {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url("../assets/cameral_group/time-delete.png") no-repeat;
  background-size: 100% 100%;
}
</style>
