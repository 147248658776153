<template>
  <a-modal
    title="轮巡预监"
    :visible="visible"
    width="40%"
    :centered="true"
    :destroyOnClose="true"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <div class="wrapper relative">
      <div class="absolute spin" v-if="loading">
        <a-spin />
      </div>
      <div class="flex items-center m-b-sm">
        <span class="m-r-xs">轮巡清晰度:</span>
        <a-radio-group name="radioGroup" v-model="definition">
          <a-radio
            v-for="(d, index) in definitionList"
            :key="index"
            :value="d.value"
          >
            {{ d.lable }}
          </a-radio>
        </a-radio-group>
      </div>
      <div class="flex items-center">
        <span class="m-r-xs">预览模式:</span>
      </div>
      <div class="content flex-wrap flex m-b-sm">
        <div class="main-picture">
          <div
            class="picture-list"
            v-for="(picture, index) in pictureList"
            :key="index"
          >
            <div
              @click="handleClick(picture)"
              class="picture-box"
              :class="screensActive == picture.id ? 'active' : ''"
            >
              <div
                class="picture-box-min"
                :class="screensActive == picture.id ? 'active' : ''"
                :style="getWidth(picture.screen, picture.litTit)"
                v-for="(v, i) in picture.screen"
                :key="i"
              ></div>
            </div>
            <span>{{ picture.name }}</span>
          </div>
        </div>
      </div>
      <div class="flex items-center m-b-sm">
        <a-checkbox :checked="intervalChecked" @change="checkedChange">
          自动巡检间隔时间
        </a-checkbox>
        <a-input-number
          v-model="intervalValue"
          :min="10"
          :max="120"
          :precision="0"
          class="m-r-xs"
        />
        <span class="m-r-xs">秒</span>
        <span> (限制为10~120)</span>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "CameraGroupSetting",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      intervalValue: 10,
      currrentScreen: "four",
      loading: false,
      litTit: "2*2",
      screensActive: 1,
      definition: 0,
      intervalChecked: false,
      definitionList: [
        {
          value: 0,
          lable: "标清",
        },
        {
          value: 1,
          lable: "高清",
        },
        {
          value: 2,
          lable: "超清",
        },
        {
          value: 3,
          lable: "原码",
        },
      ],
      pictureList: [
        { id: 1, screen: 4, mode: "four", name: "4分屏", litTit: "2*2" },
        { id: 2, screen: 6, mode: "six", name: "6分屏", litTit: "2*3" },
        { id: 3, screen: 6, mode: "six", name: "6分屏", litTit: "3*2" },
        { id: 4, screen: 9, mode: "nine", name: "9分屏", litTit: "3*3" },
        { id: 5, screen: 12, mode: "twelve", name: "12分屏", litTit: "3*4" },
        { id: 6, screen: 16, mode: "sixteen", name: "16分屏", litTit: "4*4" },
      ],
    };
  },
  computed: {},
  watch: {
    visible(nv, ov) {
      if (!nv && nv !== ov) {
        this.currrentScreen = "four";
        this.litTit = "2*2";
        this.screensActive = 1;
        this.intervalValue = 10;
        this.definition = 0;
        this.intervalChecked = false;
      }
    },
  },
  methods: {
    setLoading(loadingFlag) {
      this.loading = loadingFlag;
    },
    handleCancel() {
      this.definition = 0;
      this.intervalChecked = false;
      this.currrentScreen = "four";
      this.$emit("close");
    },
    // 分屏画面选择
    getWidth(val, litTit) {
      let obj = {
        width: "25%",
      };
      if (!val) {
        return obj;
      }
      const i = 100 / 3;
      const num = parseInt(val);
      switch (num) {
        case 4:
          obj = {
            width: "50%",
            height: "50%",
          };
          break;
        case 6:
          obj = {
            width: `${i}%`,
            height: "${i}%`",
          };
          break;
        case 9:
          obj = {
            width: `${i}%`,
            height: `${i}%`,
          };
          break;
        case 12:
          obj = {
            width: "25%",
            height: `${i}%`,
          };
          break;
        case 16:
          obj = {
            width: "25%",
            height: "25%",
          };
          break;
      }
      // 单独处理3*2的屏
      if (litTit == "3*2") {
        obj = {
          width: "50%",
          height: `${i}%`,
        };
      }
      return obj;
    },
    handleOk() {
      if (this.intervalValue === "" || Number(this.intervalValue) < 10) {
        this.$message.error("巡检间隔需在5-120秒之间");
        return;
      }
      this.$emit("currentScreen", {
        mode: this.currrentScreen,
        litTit: this.litTit,
        interval: this.intervalValue,
        definition: this.definition,
        intervalChecked: this.intervalChecked,
      });
      /* this.currrentScreen = 'single';
      this.litTit = '1*1'; */
    },
    handleClick(item) {
      this.screensActive = item.id;
      this.currrentScreen = item.mode;
      this.litTit = item.litTit;
    },
    checkedChange(e) {
      console.log("checkedChange", e);
      this.intervalChecked = e.target.checked;
    },
  },
};
</script>

<style scoped lang="less">
.wd {
  width: 33.3%;
  .wd-content {
    margin: 5px;
    border: 1px solid #243151;
    height: 80px;
    &.current {
      background: #144061;
      border: 1px solid #146ea6;
    }
  }
}
.spin {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.main-picture {
  width: 100%;
  // height: 400px;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-flow: wrap;
  .picture-list {
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .picture-box {
      box-sizing: border-box;
      width: 210px;
      height: 118px;
      border-radius: 2px;
      cursor: pointer;
      &.active {
        background: rgba(27, 71, 113, 0.3);
      }
      &.picture-box {
        display: flex;
        flex-flow: wrap;
        .picture-box-min {
          border: 1px solid #1b4871;
          &.active {
            border: 2px solid #1b4871;
          }
        }
      }
    }
    span {
      display: flex;
      height: 20px;
      font-size: 12px;
      font-weight: 400;
      color: #b6d2dd;
    }
  }
}
</style>
